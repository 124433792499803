import * as Sentry from '@sentry/sveltekit';
import {
	allowUrls,
	dsn,
	enabled,
	environment,
	ignoreErrors,
	tracesSampleRate,
	denyUrls,
} from '$lib/sentry';

Sentry.init({
	// General options
	allowUrls,
	dsn,
	enabled,
	environment,
	ignoreErrors,
	tracesSampleRate,
	// Client-specific options
	denyUrls,
	integrations: [
		Sentry.replayIntegration({
			maskAllInputs: true,
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	replaysOnErrorSampleRate: 1,
	replaysSessionSampleRate: 0.1,
});

export const handleError = Sentry.handleErrorWithSentry();
