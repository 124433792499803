import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [0,2,6,3,7,8,5,9];

export const dictionary = {
		"/": [~10],
		"/(portal-2.0)/(protected)/admin": [~24,[2,6]],
		"/(portal-2.0)/(protected)/admin/chat": [25,[2]],
		"/(portal-2.0)/(protected)/admin/customizations": [26,[2,6]],
		"/(portal-2.0)/(protected)/admin/users": [27,[2,6]],
		"/(portal-2.0)/(protected)/(generic)/advisor-chat": [14,[2,3]],
		"/(portal-2.0)/(protected)/browse-designs": [28,[2]],
		"/(portal-2.0)/(invitations)/i/[id=uuid]": [~12],
		"/(portal-2.0)/(protected)/(generic)/messages": [~15,[2,3]],
		"/(portal-2.0)/(emails)/(settings)/n/[id=uuid]": [~11],
		"/(portal-2.0)/(protected)/onboarding": [~29,[2,7]],
		"/(portal-2.0)/(protected)/onboarding/(collaborator)/co": [~30,[2,7]],
		"/(portal-2.0)/(protected)/onboarding/(customer)/cu": [~31,[2,7,8]],
		"/(portal-2.0)/(protected)/onboarding/(customer)/cu/1": [~32,[2,7,8]],
		"/(portal-2.0)/(protected)/onboarding/(customer)/cu/2": [~33,[2,7,8]],
		"/(portal-2.0)/(protected)/onboarding/(customer)/cu/3": [~34,[2,7,8]],
		"/(portal-2.0)/(protected)/onboarding/(customer)/cu/3/skip": [~35,[2,7,8]],
		"/(portal-2.0)/(protected)/(generic)/projects": [~16,[2,3,4]],
		"/(portal-2.0)/(protected)/(generic)/projects/[id=uuid]": [~17,[2,3,4,5]],
		"/(portal-2.0)/(protected)/(generic)/projects/[id=uuid]/about": [18,[2,3,4,5]],
		"/(portal-2.0)/(protected)/(generic)/projects/[id=uuid]/collaborators": [~19,[2,3,4,5]],
		"/(portal-2.0)/(protected)/(generic)/projects/[id=uuid]/files": [~20,[2,3,4,5]],
		"/(portal-2.0)/(protected)/(collaboration)/projects/[id=uuid]/files/[fileId=uuid]": [~13,[2]],
		"/(portal-2.0)/(protected)/(generic)/projects/[id=uuid]/progress": [~21,[2,3,4,5]],
		"/(portal-2.0)/(protected)/(generic)/services": [22,[2,3]],
		"/(portal-2.0)/(protected)/(generic)/set-password": [~23,[2,3]],
		"/(portal-2.0)/(sign)/sign-in": [37,[9]],
		"/(portal-2.0)/(protected)/sign-out": [36,[2]],
		"/(portal-2.0)/(sign)/sign-up": [~38,[9]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';